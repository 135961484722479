import React, { useState } from "react";
import { useAsyncRetry, useTitle } from "react-use";
import { getUserData, IUserData, setRemind, unsetRemind } from "../rpc";
import { icons } from "../assets";
import { TitleDivider } from "../hooks/TitleDivider";
import { padZero, weuiTimePicker } from "../../lib/weui";
import { useUserDataForWeixinShare } from "../common";
import { OpenHint } from "../../components/weui/OpenHint";
import { useHistory } from "react-router";

export function ProfilePage() {
  useTitle("我的数据");
  const history = useHistory();
  const state = useAsyncRetry(getUserData);
  const [showHint, setShowHint] = useState(false);
  useUserDataForWeixinShare();

  //   if (state.loading) {
  //     return <div>加载中</div>;
  //   }
  if (state.error) {
    return <div>{"出错:" + state.error}</div>;
  }

  const data = state.value || ({} as IUserData);

  const remind = (data.remindset && data.remindset[0]) || {};

  if (remind.isopen != "1") {
    remind.week = "";
  }

  return (
    <div
      className="page-profile"
      style={{
        backgroundImage: `url(${icons.profileBg})`,
      }}
    >
      {showHint && (
        <OpenHint
          onClick={() => {
            setShowHint(false);
          }}
        >
          请点击右上角的
          <br />
          “···”进行分享
        </OpenHint>
      )}
      <header>
        <img className="user-head big" alt="" src={data?.headimgurl} />
        <p>{data?.nickname}</p>
        <div className="btn-grids">
          <div
            className="grid"
            onClick={() => {
              history.push("/exercise-records");
            }}
          >
            <img src={icons.jianshen} alt="" />
            <div>
              <span>{data?.sportcount}</span>
              <br />
              运动次数
            </div>
          </div>
          <div className="line"></div>
          <div
            className="grid"
            onClick={() => {
              history.push("/fen-records");
            }}
          >
            <img src={icons.jifen} alt="" />
            <div>
              <span>{data?.points}</span>
              <br />
              我的积分
            </div>
          </div>
        </div>
      </header>
      <div className="cells">
        <div
          className="cell"
          onClick={async () => {
            setShowHint(true);
          }}
        >
          <img src={icons.tuijian} alt="" />
          <label>推荐赚积分</label>
          <img src={icons.more} alt="" />
        </div>
        <div
          className="cell"
          onClick={() => {
            weuiTimePicker(
              remind.week,
              remind.hour,
              remind.minute,
              async function (week, hour, minute) {
                if (!week) {
                  await unsetRemind();
                } else {
                  await setRemind(week, hour, minute);
                }
                state.retry();
              }
            );
          }}
        >
          <img src={icons.tixing} alt="" />
          <label>提醒我运动</label>
          <span>
            {remind.isopen == "1"
              ? `${padZero(remind.hour)}:${padZero(remind.minute)}`
              : "未设置"}
          </span>
        </div>
        <div
          className="cell"
          onClick={() => {
            history.push("/fen");
          }}
        >
          <img src={icons.duihuan} alt="" />
          <label>积分兑换</label>
          <img src={icons.more} alt="" />
        </div>
      </div>

      <div className="user-list">
        <TitleDivider>推荐达人</TitleDivider>
        {data.recommend && (
          <div>
            {data.recommend.slice(0, 5).map((r) => (
              <img className="user-head" src={r.headimgurl} alt="" />
            ))}
          </div>
        )}
      </div>

      <div className="user-list">
        <TitleDivider>技术支持</TitleDivider>
        <div>
          <img
            className="user-head"
            src="https://thirdwx.qlogo.cn/mmopen/vi_32/yktbzRCibvs6CJwxnHg8TlLTPrG860rdWpfO8rnHFawib3HR3IK0IPcibYuF7glBk3sPiczUsxWNgO3Wbbk4HEfNvg/132"
            alt=""
          />
          <img
            className="user-head"
            src="http://thirdwx.qlogo.cn/mmopen/ajNVdqHZLLBy1YCicUS1PCicOpib53KKfzwB6cK314icnD3iaytLwkeDYXq2HkjQwTecYmR6iaWEP45N2WPhKq5SbgAyVIaW6AH8Y5u9Rs6fGxjMY/132"
            alt=""
          />
          <img
            className="user-head"
            src="https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJdyj4xX4WfgdqgAV37B7icbw91KfAgJx03XdeUhEic73bB9p4yibSiaRO1Bw2vy3D5ibgX7p19Jwl4TKQ/132"
            alt=""
          />
          <img
            className="user-head"
            src="http://thirdwx.qlogo.cn/mmopen/PL5y7QQHZgI66StGuXgkWfIdMWqJksqv3c9hicj6XCAedjsUJf4JP5Q9cZWNj8DsDkvIF2icDATxxzTgyVAYgdicWrcWyT4uekx/132"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
