import React, { useState } from "react";
import { useAsync, useTitle } from "react-use";
import Lottie from "lottie-react";
import { fits, IFit } from "../pose";
import { getRankingList, getUserData, IRankingUser } from "../rpc";
import { OpenHint } from "../../components/weui/OpenHint";
import { useUserDataForWeixinShare } from "../common";
import { useHistory } from "react-router-dom";
import { icons } from "../assets";

function RankingItem({ user, i }: { user: IRankingUser; i: number }) {
  return (
    <div className="ranking-item">
      <i className={["first", "second", "third"][i] || ""}>{i + 1}</i>
      <img className="user-head" alt="head" src={user.headimgurl} />
      <label> {user.nickname} </label>
      <span className="score">
        <big>{user.score}</big>分
      </span>
    </div>
  );
}

export function RankingPage() {
  useTitle("今日排行");
  useUserDataForWeixinShare("今日排行", "/ranking");
  const history = useHistory();
  const state = useAsync(getRankingList);
  const [showHint, setShowHint] = useState(false);

  // if (state.loading) {
  //   return <div>加载中</div>;
  // }
  if (state.error) {
    return <div>{"出错:" + state.error}</div>;
  }

  let rankinglist: IRankingUser[] = [];
  let userRanking: IRankingUser = {} as any;
  if (state.value?.rankinglist) {
    rankinglist = state.value?.rankinglist[1].list;
    userRanking = state.value?.rankinglist[0].list[0];
  }

  return (
    <div
      className="page-ranking"
      style={{
        backgroundImage: `url(${icons.rankingBg}), linear-gradient(46deg, #ff827c 1%, #ffde9d 99%)`,
      }}
    >
      {showHint && (
        <OpenHint
          onClick={() => {
            setShowHint(false);
          }}
        >
          请点击右上角的
          <br />
          “···”进行分享
        </OpenHint>
      )}
      <button
        className="button"
        onClick={async () => {
          setShowHint(true);
        }}
      >
        分享榜单
      </button>
      <h1>运动达人</h1>
      <main>
        {rankinglist?.map((u, i) => (
          <RankingItem key={i} user={u} i={i} />
        ))}
      </main>
      <footer>
        {userRanking?.score && (
          <div className="user-ranking">
            <img
              className="user-head big"
              alt=""
              src={userRanking?.headimgurl}
            />
            <div>
              <span style={{ fontSize: "20px" }}>{userRanking?.nickname}</span>
              <br />
              <span className="small">第{userRanking?.sort || "?"}名</span>
            </div>
            <div className="action-list"></div>
            <span className="score">
              <big className="big">{userRanking?.score || "0"}</big> 分
            </span>
          </div>
        )}
        {!userRanking?.score && (
          <button
            onClick={() => {
              history.push("/");
            }}
          >
            <Lottie animationData={fits[0].animation} className="action-icon" />
            开始运动
            <Lottie animationData={fits[1].animation} className="action-icon" />
          </button>
        )}
      </footer>
    </div>
  );
}
