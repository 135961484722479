import Lottie from "lottie-react";
import React, { FunctionComponent } from "react";
import { usePose } from "../hooks/usePose";
import { IFit } from "../pose";
import { BgmSwitch } from "../hooks/BgmSwitch";
import { icons, sounds } from "../assets";

function Loading() {
  return <img alt="Loading" src={icons.loading} className="spin" />;
}

interface IGamePosePageProps {
  nextVoice: boolean;
  fit: IFit;
  onClose: () => void;
  onEnd: (score: number) => void;
}

export const GamePosePage: FunctionComponent<IGamePosePageProps> = function ({
  nextVoice,
  fit,
  onClose,
  onEnd,
}) {
  let { status, counter, refCanvas } = usePose(fit.url, fit.steps);

  if (counter && counter.count === fit.count) {
    onEnd(fit.score);
  }

  return (
    <div className="page-game">
      <header>
        <h2>{fit.title}</h2>
        <div className="counter">
          <span className="n">{counter?.count || 0}</span> /{" "}
          <span className="m">{fit.count}</span> 次 ({(counter?.step || 0) + 1}/
          {counter?.steps})
        </div>
        <img
          className="button"
          alt="close"
          src={icons.close}
          onClick={onClose}
        />
      </header>
      <main>
        {(status === 2 || status === 3) && <canvas ref={refCanvas}></canvas>}
        <div className="center">
          {status === 0 && (
            <p>
              <Loading />
              <br />
              正在打开摄像头
            </p>
          )}
          {status === -1 && <p>打开摄像头出错</p>}
          {status === 1 && (
            <p>
              <Loading />
              <br />
              正在加载动作
            </p>
          )}
          {status === -2 && <p>加载动作出错</p>}
          {status === 2 && (
            <p>
              请走入镜头
              <audio src={sounds.voice.start} autoPlay />
            </p>
          )}
          {status === 3 && (
            <>
              {nextVoice && <audio src={sounds.voice.continue} autoPlay />}
              <Lottie animationData={fit.animation} />
              <p>请跟随演示做动作</p>
            </>
          )}
        </div>
      </main>
      <footer>
        <BgmSwitch />
        <h2>Tips</h2>
        <p>
          请按示意动画人物完成动作，动作符合标准记入次数，需完成{fit.count}
          次，最高得{fit.score}积分。
        </p>
      </footer>
    </div>
  );
};
