import React, { FunctionComponent, useRef, useState } from "react";
import { useHistory } from "react-router";
import { coverVideoToCanvas } from "../../lib/dom";
import { getCamera } from "../pose";
import { ScoreDialog } from "../hooks/ScoreDialog";
import { icons, sounds } from "../assets";
import { useAnimationFrame } from "../../components/useAnimationFrame";
import { mpURL } from "../common";

interface IGameResultPageProps {
  score: number;
  guanzhu: boolean;
}

export const GameResultPage: FunctionComponent<IGameResultPageProps> = function ({
  score,
  guanzhu,
}) {
  const history = useHistory();
  const refCanvas = useRef(null);
  const webcam = getCamera();
  const [hideDialog, setHideDialog] = useState(false);

  useAnimationFrame(() => {
    if (refCanvas.current && webcam) {
      coverVideoToCanvas(webcam.webcam, refCanvas.current!, true);
    }
  });

  return (
    <div className="page-game">
      <main>
        <audio src={sounds.voice.end} autoPlay />
        <canvas ref={refCanvas}></canvas>
        {!hideDialog && (
          <ScoreDialog
            score={score}
            onClose={() => {
              setHideDialog(true);
              if (guanzhu) {
                history.goBack();
                return;
              }
              weui.dialog({
                title: "关注公众号",
                content: `关注【闲来运动】，做运动，赚积分，兑红包，噢耶！`,
                buttons: [
                  {
                    label: "确定",
                    type: "primary",
                    onClick: function () {
                      window.location.href = mpURL;
                    },
                  },
                ],
              });
            }}
          />
        )}
      </main>
    </div>
  );
};
