import { FunctionComponent } from "react";
import { weixinBrowser } from "../../lib/navigator";
import hintIcon from "./OpenHint.png";
import "./OpenHint.scss";

export const OpenHint: FunctionComponent<{ onClick?: () => void }> = ({
  onClick,
  children,
}) => {
  if (!weixinBrowser()) {
    return null;
  }
  return (
    <div className="weui-open-hint" onClick={onClick}>
      <img src={hintIcon} alt="" />
      <p>{children}</p>
    </div>
  );
};

export const OpenHintBrowser: FunctionComponent = () => {
  return (
    <OpenHint>
      点击右上角 <br />
      <span>选择在浏览器中打开</span>
    </OpenHint>
  );
};
