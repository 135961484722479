import Lottie from "lottie-react";
import { useAsync, useTitle } from "react-use";
import { fits } from "../pose";
import { getExerciseRecords, ISportData } from "../rpc";

export function ExerciseRecordsPage() {
  useTitle("运动记录");
  const state = useAsync(getExerciseRecords);

  // if (state.loading) {
  //   return <div>加载中</div>;
  // }
  if (state.error) {
    return <div>{"出错:" + state.error}</div>;
  }

  let records: ISportData[] = state.value?.data || [];

  return (
    <div className="page-exercise-records">
      <main>
        {records.map((r) => {
          const icons = r.sportlist
            .map((s) => {
              const fit = fits.find((f) => f.name == s.key);
              return [...Array(parseInt(s.count))].map(() => fit);
            })
            .flat();

          return (
            <div key={r.listtime} className="record">
              <div style={{ flex: 1 }}>
                <p style={{ fontSize: "15px" }}>
                  {icons.map((f, i) => (
                    <img key={i} src={f?.icon} className="action-icon" alt="" />
                  ))}
                </p>
                <p style={{ fontSize: "12px", color: "#ADADB3" }}>
                  运动日期: {r.listtime}
                </p>
              </div>
              <p className="score">{r.total} 次</p>
            </div>
          );
        })}
        <p className="hint">只显示最近30天</p>
      </main>
    </div>
  );
}
