import { FunctionComponent } from "react";

export const Dialog: FunctionComponent<{ onClose?: () => void }> = function ({
  onClose,
  children,
}) {
  return (
    <div className="dialog">
      <div className="backdrop" onClick={onClose}></div>
      {children}
    </div>
  );
};
