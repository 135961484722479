import * as tmPose from "@teachablemachine/pose";
import { useRef, useState } from "react";
import { useAnimationFrame } from "../../components/useAnimationFrame";
import { predictPoseFrame } from "../../lib/tmPose";
import { ActionCounter, getStep, loadModel, openCamera } from "../pose";

/*
1. 打开摄像头
2. 加载模型
3. 等待走进镜头
4. 开始计数
5. 结束
*/

interface IPoseState {
  status: number;
  webcam?: tmPose.Webcam;
  model?: tmPose.CustomPoseNet;
  counter?: ActionCounter;
}
export function usePose(url: string, steps: number) {
  const [_, setState] = useState({});
  const refCanvas = useRef<HTMLCanvasElement>(null);
  const ref = useRef<IPoseState>({
    status: 0,
  });
  const state = ref.current;

  (async function () {
    if (state.status === 0) {
      try {
        state.webcam = await openCamera();
        state.status = 1;
      } catch (e) {
        console.log("open camera error:" + e);
        // alert("打开摄像头错误:" + e.message);
        state.status = -1;
      }
      setState({});
    } else if (state.status === 1) {
      try {
        state.model = await loadModel(url);
        state.status = 2;
      } catch (e) {
        console.log("load model error:" + e);
        state.status = -2;
      }
      setState({});
    }
  })();

  useAnimationFrame(async () => {
    if (state.status === 2) {
      const { pose } = await predictPoseFrame(
        state.webcam!!,
        state.model!!,
        refCanvas.current
      );
      if (pose) {
        const ok = pose.keypoints.filter((p) => p.score > 0.5).length > 10;
        if (ok) {
          state.status = 3; //完成人体检测
          state.counter = new ActionCounter(steps);
          setState({});
        }
      }
    } else if (state.status === 3) {
      const { prediction } = await predictPoseFrame(
        state.webcam!!,
        state.model!!,
        refCanvas.current
      );
      const step = getStep(prediction!);
      if (state.counter?.checkPose(step)) {
        setState({});
      }
    }
  });

  return { status: state.status, counter: state.counter, refCanvas };
}
