import React, { FunctionComponent, useState } from "react";
import { useHistory } from "react-router";
import { useUserDataForWeixinShare } from "../common";
import { fits } from "../pose";
import { postUserExercise } from "../rpc";
import { store } from "../store";
import { GamePosePage } from "./GamePosePage";
import { GameResultPage } from "./GameResultPage";

export const GamePage: FunctionComponent = function () {
  const poseNames = store.getSelectedActions();
  const selectedFits = fits.filter((f) => poseNames.indexOf(f.name) > -1);

  const history = useHistory();
  const [fitIndex, setFitIndex] = useState(0);
  const [score, setScore] = useState(0);
  const userDataState = useUserDataForWeixinShare();

  if (fitIndex >= selectedFits.length) {
    return (
      <GameResultPage
        score={score}
        guanzhu={userDataState.value?.isgz == "1"}
      />
    );
  }

  const fit = selectedFits[fitIndex];
  return (
    <GamePosePage
      nextVoice={fitIndex > 0}
      key={fit.name}
      fit={fit}
      onClose={() => {
        history.goBack();
      }}
      onEnd={async (s) => {
        setScore(score + s);
        setFitIndex(fitIndex + 1);
        await postUserExercise(fit.name, s);
      }}
    />
  );
};
