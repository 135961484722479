import { useAsync } from "react-use";
import { iOS, landingURL, relativeUrl } from "../lib/navigator";
import { weixinConfig, weixinShare } from "../lib/weixin";
import { getUserData, getWeixinConfig, postRecommend } from "./rpc";

async function tryPostRecommend() {
  const params = new URLSearchParams(window.location.search);
  const from = params.get("fromuid");
  if (from) {
    postRecommend(from).then((r) => {
      // alert("设置推荐人结果:" + JSON.stringify(r));
      // params.delete("fromuid");
      // const search = params.toString();
      // window.history.replaceState(
      //   null,
      //   "",
      //   window.location.pathname + (search ? "?" + search : "")
      // );
    });
  }
}

export const mpURL =
  "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU2ODU5OTIyMw==&scene=124#wechat_redirect";

async function initWeixinShare(userID: number, desc: string, uri: string) {
  const url = relativeUrl(uri + "?fromuid=" + userID);
  const appicon = relativeUrl("/logo.png");
  const configUrl = iOS() ? landingURL : window.location.href;
  const config = await getWeixinConfig(configUrl);
  weixinConfig({
    ...config,
    url: configUrl,
    jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
  });
  weixinShare("闲来运动", url, appicon, desc);
}

export function useUserDataForWeixinShare(desc = "开始运动", uri = "/") {
  const state = useAsync(getUserData);
  const userID = state.value?.userid as any;
  if (userID) {
    tryPostRecommend();
    initWeixinShare(userID, desc, uri);
  }
  return state;
}
