export const landingURL = window.location.href;

export function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export function weixinBrowser() {
  return !!navigator.userAgent.match(/MicroMessenger/i);
}

export function android() {
  return (
    navigator.userAgent.indexOf("Android") > -1 ||
    navigator.userAgent.indexOf("Linux") > -1
  ); //android终端或者uc浏览器
}

export function relativeUrl(uri: string) {
  return new URL(uri, document.baseURI).href;
}
