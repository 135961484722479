import * as tmPose from "@teachablemachine/pose";
import { copyVideoToCanvas, coverVideoToCanvas } from "./dom";

export async function loadPoseModel(
  URL: string
): Promise<tmPose.CustomPoseNet> {
  const modelURL = URL + "model.json";
  const metadataURL = URL + "metadata.json";
  const model = await tmPose.load(modelURL, metadataURL);
  return model;
}

export async function openPoseCamera(): Promise<tmPose.Webcam> {
  const webcam = new tmPose.Webcam(0, 0, true); // width, height, flip
  await webcam.setup(); // request access to the webcam
  try {
    await webcam.play();
  } catch (e) {
    weui.alert("准备好了吗？", function () {
      webcam.play();
    });
  }
  return webcam;
}

export async function predictPoseFrame(
  webcam: tmPose.Webcam,
  model: tmPose.CustomPoseNet,
  canvas?: HTMLCanvasElement | null
) {
  if (!webcam.webcam.videoWidth) {
    return {};
  }
  // webcam.update(); // NOTE: don't do this
  copyVideoToCanvas(webcam.webcam, webcam.canvas);

  // Prediction #1: run input through posenet
  // estimatePose can take in an image, video or canvas html element
  const { pose, posenetOutput } = await model.estimatePose(webcam.canvas);
  // Prediction 2: run input through teachable machine classification model
  const prediction = await model.predict(posenetOutput);

  // finally draw the poses
  // draw the keypoints and skeleton
  if (canvas) {
    const ctx = coverVideoToCanvas(webcam.canvas, canvas, true);
    if (pose) {
      const minPartConfidence = 0.5;
      tmPose.drawKeypoints(
        pose.keypoints,
        minPartConfidence,
        ctx,
        4,
        "#F89C52",
        "#F89C52"
      );
      tmPose.drawSkeleton(pose.keypoints, minPartConfidence, ctx, 2, "#F89C52");
    }
  }

  return { pose, prediction };
}
