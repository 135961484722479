interface IWeixinPayWebParams {
  appId: string; //公众号id	appId	是	String(16)	wx8888888888888888	商户注册具有支付权限的公众号成功后即可获得
  timeStamp: string; //时间戳	timeStamp	是	String(32)	1414561699	当前的时间，其他详见时间戳规则
  nonceStr: string; //随机字符串	nonceStr	是	String(32)	5K8264ILTKCH16CQ2502SI8ZNMTM67VS	随机字符串，不长于32位。推荐随机数生成算法
  package: string; //订单详情扩展字符串	package	是	String(128)	prepay_id=123456789	统一下单接口返回的prepay_id参数值，提交格式如：prepay_id=***
  signType: string; //签名方式	signType	是	String(32)	MD5	签名类型，默认为MD5，支持HMAC-SHA256和MD5。注意此处需与统一下单的签名类型一致
  paySign: string; //签名	paySign	是	String(64)	C380BEC2BFD727A4B6845133519F3AD6	签名，详见签名生成算法
}

interface IWeixinPayWebResponse {
  //
  err_msg: string;
}

export function weixinPay(params: IWeixinPayWebParams): Promise<any> {
  return new Promise((resolve, reject) => {
    //@ts-ignore
    window.WeixinJSBridge.invoke(
      "getBrandWCPayRequest",
      params,
      (res: IWeixinPayWebResponse) => {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          resolve(res);
        } else {
          reject(res);
        }
      }
    );
  });
}

export function weixinShare(
  title: string,
  link: string,
  imgUrl: string,
  desc: string
) {
  //@ts-ignore
  const { wx } = window;
  wx.ready(() => {
    // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。

    //需在用户可能点击分享按钮前就先调用
    wx.updateAppMessageShareData({
      title, // 分享标题
      desc, // 分享描述
      link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl, // 分享图标
    });
    wx.updateTimelineShareData({
      title, // 分享标题
      link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl, // 分享图标
    });
  });
}

interface IConfig {
  debug?: boolean; // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
  appId: string; // 必填，公众号的唯一标识
  timestamp: string; // 必填，生成签名的时间戳
  nonceStr: string; // 必填，生成签名的随机串
  signature: string; // 必填，签名
  jsApiList: string[]; // 必填，需要使用的JS接口列表
}

export function weixinConfig(config: IConfig) {
  //需在用户可能点击分享按钮前就先调用
  wx.config(config);
}

export function weixinLogin(appid: string, state = "weixin-oauth-code") {
  if (!navigator.userAgent.match(/MicroMessenger/i)) {
    console.log("非微信浏览器:" + window.location);
    return;
  }
  const redirect_uri = encodeURIComponent(window.location.href);
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
  window.location.href = url;
}
