import * as tmPose from "@teachablemachine/pose";
import { loadPoseModel, openPoseCamera } from "../lib/tmPose";
import { animations, icons } from "./assets";

let webcam: tmPose.Webcam;

export async function openCamera() {
  if (!webcam) {
    webcam = await openPoseCamera();
  }
  return webcam;
}

export function getCamera() {
  return webcam;
}

const models: { [url: string]: tmPose.CustomPoseNet } = {};

export async function loadModel(url: string) {
  if (!models[url]) {
    models[url] = await loadPoseModel(url);
  }
  return models[url];
}

////////////////////////////////

export interface IFit {
  name: string;
  title: string;
  url: string;
  animation: any;
  icon: any;
  steps: number;
  count: number;
  score: number;
}

export const fits: IFit[] = [
  {
    name: "jianzhouyan",
    title: "肩周炎",
    url: "/models/wrt4T4kKv/",
    animation: animations.jianzhouyan,
    icon: icons.fits.jianzhouyan,
    steps: 3,
    count: 20,
    score: 20,
  },
  {
    name: "jingzhuisuan",
    title: "颈椎酸",
    url: "/models/AuazIWDRD/",
    animation: animations.jingzhuisuan,
    icon: icons.fits.jingzhuisuan,
    steps: 3,
    count: 20,
    score: 20,
  },
  {
    name: "yaojianpan",
    title: "腰间盘",
    url: "/models/-3OFsx_nv/",
    animation: animations.yaojianpan,
    icon: icons.fits.yaojianpan,
    steps: 3,
    count: 20,
    score: 20,
  },
  {
    name: "lajianjing",
    title: "拉肩颈",
    url: "/models/BwIlHMWOx/",
    animation: animations.lajianjing,
    icon: icons.fits.lajianjing,
    steps: 2,
    count: 20,
    score: 20,
  },
  {
    name: "tongjingluo",
    title: "通经络",
    url: "/models/3VtCtEWuq/",
    animation: animations.tongjingluo,
    icon: icons.fits.tongjingluo,
    steps: 2,
    count: 20,
    score: 20,
  },
];

export function getStep(
  prediction: {
    className: string;
    probability: number;
  }[]
) {
  let mp = prediction[0];
  for (const p of prediction) {
    if (!mp || mp.probability < p.probability) {
      mp = p;
    }
  }
  if (prediction.length == 2 && mp.probability < 0.8) {
    return -1;
  }
  return prediction.indexOf(mp);
}

export class ActionCounter {
  public step: number;
  public count: number;

  constructor(public steps: number) {
    this.step = 0;
    this.count = 0;
  }

  checkPose(step: number) {
    if (step > this.steps - 1) {
      //无效状态
      return false;
    }
    if (this.step !== step) {
      if (this.step === this.steps - 1 && step === 0) {
        this.step = step;
        this.count++;
      } else if (step == this.step + 1) {
        this.step = step;
      }
      return true;
    }
    return false;
  }

  toString() {
    return `计数:${this.count}, 当前进度:${this.step}/${this.steps}`;
  }
}
