import React, { useState } from "react";
import { useAsync, useTitle } from "react-use";
import { getFenRecords, getFenUseRecords } from "../rpc";

export function FenRecordsPage() {
  useTitle("积分记录");
  const state0 = useAsync(getFenRecords);
  const state1 = useAsync(getFenUseRecords);
  const [tab, setTab] = useState(0);

  // if (state.loading) {
  //   return <div>加载中</div>;
  // }
  if (state0.error) {
    return <div>{"出错:" + state0.error}</div>;
  }
  if (state1.error) {
    return <div>{"出错:" + state1.error}</div>;
  }

  const tabs = [
    {
      title: "赚取记录",
      active: tab == 0,
    },
    {
      title: "兑换记录",
      active: tab == 1,
    },
  ];

  let records0 = state0.value?.data || [];
  let records1 = state1.value?.data || [];
  let records = tab == 0 ? records0 : records1;

  return (
    <div className="page-fen-records">
      <header>
        {tabs.map((t, i) => (
          <div
            className={"tab " + (t.active ? "active" : "")}
            onClick={() => {
              setTab(i);
            }}
          >
            {t.title}
          </div>
        ))}
      </header>
      <main>
        {records?.map((r: any) => (
          <div className="record">
            <p className="score">{r.points} 分</p>
            <p style={{ fontSize: "15px" }}>{r.type || `红包 ${r.cash} 元`}</p>
            <p style={{ fontSize: "12px", color: "#ADADB3" }}>{r.time}</p>
            <div style={{ clear: "both" }}></div>
          </div>
        ))}
      </main>
    </div>
  );
}
