export const store = {
  getSelectedActions() {
    const actions: string[] = JSON.parse(
      localStorage.getItem("SelectedActions") || "[]"
    );
    return actions;
  },

  setSelectedActions(actions: string[]) {
    localStorage.setItem("SelectedActions", JSON.stringify(actions));
  },

  getUserToken() {
    const token = localStorage.getItem("UserToken2");
    if (!token && window.location.hostname == "localhost") {
      return "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MTM4OTY2MzQuMCwiaWQiOiJvdDRDMDFOQzZqeGpKR1Mtd09KMW5WMjBvYmc0In0.OqiVBeAjWrIK_qNW0ay5tDinQLOuQT7erDDnQahoLXA";
    }
    return token
  },

  setUserToken(token: string) {
    localStorage.setItem("UserToken2", token);
  },

  getBGM() {
    return localStorage.getItem("BGM");
  },

  setBGM(name: string) {
    localStorage.setItem("BGM", name);
  },
};
