import { FunctionComponent, ReactElement } from "react";
import { weixinLogin } from "../lib/weixin";

async function handle({ appid, state, isRequired, onCode }: IWeixinOAuthProps) {
  const params = new URLSearchParams(window.location.search);

  if (!state || params.get("state") === state) {
    const code = params.get("code");

    if (code) {
      await onCode(code);
      // remove code and state params
      params.delete("code");
      params.delete("state");
      const search = params.toString();
      const uri = window.location.pathname + (search ? "?" + search : "");
      // window.history.replaceState(null, "", uri);
      window.location.href = uri;
      return;
    }
  }

  if (await isRequired()) {
    weixinLogin(appid);
  }
}

interface IWeixinOAuthProps {
  appid: string;
  state?: string;
  isRequired: () => boolean | Promise<boolean>;
  onCode: (code: string) => void;
}

export const WeixinOAuth: FunctionComponent<IWeixinOAuthProps> = function ({
  children,
  ...props
}) {
  handle(props);
  return children as ReactElement;
};
