import voice01 from "./sounds/voice/01-start.m4a";
import voice02 from "./sounds/voice/02-end.m4a";
import voice03 from "./sounds/voice/03-continue.m4a";
import bgm01 from "./sounds/bgm/Chronos.mp3";
import bgm02 from "./sounds/bgm/Jethro on the Run.mp3";
import bgm03 from "./sounds/bgm/Take the Ride.mp3";
import iconAdd from "./icons/add.svg";
import iconMore from "./icons/more.svg";
import iconClose from "./icons/close.svg";
import iconHelp from "./icons/help.svg";
import iconGzh from "./icons/gzh.svg";
import iconLoading from "./icons/loading.svg";
import iconBgmOff from "./icons/music-off.svg";
import iconBgmOn from "./icons/music-on.svg";
import iconToggleOn from "./icons/toggle-on.svg";
import iconToggleOff from "./icons/toggle-off.svg";
import iconProfileBg from "./icons/profile-bg.svg";
import iconRankingBg from "./icons/ranking-bg@2x.png";
import iconDuihuan from "./icons/duihuan.svg";
import iconjianshen from "./icons/jianshen.svg";
import iconJifen from "./icons/jifen.svg";
import iconTixing from "./icons/tixing.svg";
import iconTuijian from "./icons/tuijian.svg";
import iconFit01 from "./icons/fits/01.png";
import iconFit02 from "./icons/fits/02.png";
import iconFit03 from "./icons/fits/03.png";
import iconFit04 from "./icons/fits/04.png";
import iconFit05 from "./icons/fits/05.png";
import animJianzhouyan from "./animations/01_jianzhouyan.json";
import animJingzhuisuan from "./animations/02_jingzhuisuan.json";
import animYaojianpan from "./animations/03_yaojianpan.json";
import animLajianjing from "./animations/04_lajianjing.json";
import animTongjingluo from "./animations/05_tongjingluo.json";

export const animations = {
  jianzhouyan: animJianzhouyan,
  jingzhuisuan: animJingzhuisuan,
  yaojianpan: animYaojianpan,
  lajianjing: animLajianjing,
  tongjingluo: animTongjingluo,
};

export const sounds = {
  voice: {
    start: voice01,
    end: voice02,
    continue: voice03,
  },
  bgm: {
    Chronos: bgm01,
    "Jethro on the Run": bgm02,
    "Take the Ride": bgm03,
  } as { [key: string]: string },
};

export const icons = {
  add: iconAdd,
  more: iconMore,
  close: iconClose,
  help: iconHelp,
  gzh: iconGzh,
  loading: iconLoading,
  bgmOff: iconBgmOff,
  bgmOn: iconBgmOn,
  toggleOn: iconToggleOn,
  toggleOff: iconToggleOff,

  profileBg: iconProfileBg,
  rankingBg: iconRankingBg,
  duihuan: iconDuihuan,
  jianshen: iconjianshen,
  jifen: iconJifen,
  tixing: iconTixing,
  tuijian: iconTuijian,

  fits: {
    jianzhouyan: iconFit01,
    jingzhuisuan: iconFit02,
    yaojianpan: iconFit03,
    lajianjing: iconFit04,
    tongjingluo: iconFit05,
  },
};
