import React, { FunctionComponent } from "react";
import { Dialog } from "../../components/Dialog";
import imageScoreHeader from "../icons/score-header.png";
import imageScoreHeader2 from "../icons/score-header@2x.png";

interface IScoreDialogProps {
  score: number;
  onClose: () => void;
}

export const ScoreDialog: FunctionComponent<IScoreDialogProps> = function ({
  score,
  onClose,
}) {
  return (
    <Dialog>
      <div className="center">
        <div className="dialog-score">
          <img
            className="header"
            alt=""
            src={imageScoreHeader}
            srcSet={imageScoreHeader2 + " 2x"}
          />
          <p className="golden">恭喜您获得</p>
          <p className="red">
            <big>{score}</big> 分
          </p>
          <p className="gray">动作非常标准，再接再厉！</p>
          <button className="button" onClick={onClose}>
            朕知道了
          </button>
        </div>
      </div>
    </Dialog>
  );
};
