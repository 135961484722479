import { FunctionComponent, useState } from "react";
import { Dialog } from "../../components/Dialog";
import { icons, sounds } from "../assets";
import { store } from "../store";

export const BgmSwitch: FunctionComponent = function (props) {
  const bgm = store.getBGM();
  const bgmUrl = bgm && sounds.bgm[bgm];
  const imgUrl = bgmUrl ? icons.bgmOn : icons.bgmOff;
  const toggleUrl = bgmUrl ? icons.toggleOn : icons.toggleOff;

  const [show, setShow] = useState(false);

  return (
    <div>
      {bgmUrl && <audio loop autoPlay src={bgmUrl}></audio>}
      <img
        className="button"
        alt=""
        src={imgUrl}
        onClick={() => {
          setShow(true);
        }}
      />

      {show && (
        <Dialog
          onClose={() => {
            setShow(false);
          }}
        >
          <div className="dialog-bgm">
            <div style={{ marginBottom: "16px" }}>
              <img
                alt=""
                src={toggleUrl}
                className="toggle"
                onClick={() => {
                  store.setBGM("");
                  setShow(false);
                }}
              />
              选择音乐
            </div>
            <div className="content">
              {Object.entries(sounds.bgm).map(([key, v]) => (
                <div
                  className={`cell ${key == bgm ? "selected" : ""}`}
                  onClick={() => {
                    store.setBGM(key);
                    setShow(false);
                  }}
                >
                  {key}
                </div>
              ))}
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};
