import React, { useState } from "react";
import { useAsyncRetry, useTitle } from "react-use";
import { getFenData, postFenExchange } from "../rpc";
import { useUserDataForWeixinShare } from "../common";

export function FenPage() {
  useTitle("积分兑换");
  useUserDataForWeixinShare();
  const state = useAsyncRetry(getFenData);
  const [coins, setCoins] = useState(0);

  // if (state.loading) {
  //   return <div>加载中</div>;
  // }
  if (state.error) {
    return <div>{"出错:" + state.error}</div>;
  }

  const { cash, points } = state.value || { cash: 0, points: 0 };

  const plans = [1, 2, 5, 10, 20, 50];

  return (
    <div className="page-fen">
      <header>
        <span className="big">{points}</span> 积分
        <p className="hint">1.每天做运动最高可获 100 积分</p>
        <p className="hint">2.成功推荐朋友做运动，可获 500 积分/人</p>
      </header>
      <main>
        <p className="hint">今日剩余可兑换余额: {cash}元</p>
        <div className="cells">
          {plans.map((y) => (
            <div
              key={y}
              className={`cell ${
                y == coins ? "selected" : y * 1000 > points ? "disabled" : ""
              }`}
              onClick={() => {
                if (y * 1000 > points) {
                  return;
                }
                setCoins(y);
              }}
            >
              <p>{y} 元</p>
              <p className="small">{y * 1000} 积分</p>
            </div>
          ))}
        </div>
      </main>
      <footer>
        <button
          className="button"
          onClick={async () => {
            if (!coins) {
              return;
            }
            const { code, msg } = await postFenExchange(coins * 1000);
            if (code) {
              weui.alert("积分已兑换，请您留意微信钱包！");
              state.retry();
            } else {
              weui.alert(msg);
            }
          }}
        >
          兑换红包
        </button>
      </footer>
    </div>
  );
}
