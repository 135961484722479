import { Switch, Route, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { getUserToken, refreshUserToken } from "./rpc";
import { store } from "./store";
import { WeixinOAuth } from "../components/WeixinOAuth";
import "./App.scss";
import { GamePage } from "./routes/GamePage";
import { PreparePage } from "./routes/PreparePage";
import { RankingPage } from "./routes/RankingPage";
import { ProfilePage } from "./routes/ProfilePage";
import { FenPage } from "./routes/FenPage";
import { FenRecordsPage } from "./routes/FenRecordsPage";
import { ExerciseRecordsPage } from "./routes/ExerciseRecordsPage";

export const App = () => (
  <BrowserRouter>
    <WeixinOAuth
      appid="wx728ff82d04c8ecb3"
      isRequired={async () => {
        let token = store.getUserToken();
        if (!token) {
          return true;
        }
        token = await refreshUserToken(token);
        if (!token) {
          return true;
        }
        store.setUserToken(token);
        return false;
      }}
      onCode={async (code) => {
        try {
          // alert("code:" + code);
          const token = await getUserToken(code);
          // alert("token:" + token);
          if (token) {
            store.setUserToken(token);
          }
        } catch (e) {
          alert(e.message);
        }
      }}
    >
      <Switch>
        <Route path="/game" component={GamePage} />
        <Route path="/ranking" component={RankingPage} />
        <Route path="/profile" component={ProfilePage} />
        <Route path="/fen" component={FenPage} />
        <Route path="/fen-records" component={FenRecordsPage} />
        <Route path="/exercise-records" component={ExerciseRecordsPage} />
        <Route path="/" component={PreparePage} exact />
        <Redirect to="/" />
      </Switch>
    </WeixinOAuth>
  </BrowserRouter>
);
