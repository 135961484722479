import { FunctionComponent } from "react";

import "./TitleDivider.scss";

export const TitleDivider: FunctionComponent = function ({ children }) {
  return (
    <div className="title-divider">
      <div className="line"></div>
      <div className="title">{children}</div>
      <div className="line"></div>
    </div>
  );
};
