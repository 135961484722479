import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Lottie from "lottie-react";
import { fits, IFit } from "../pose";
import { store } from "../store";
import { useTitle } from "react-use";
import { icons } from "../assets";
import { mpURL, useUserDataForWeixinShare } from "../common";

function ActionItem({ fit, onAdd }: { fit: IFit; onAdd: (() => void) | null }) {
  return (
    <div className="action-item">
      <Lottie animationData={fit.animation} className="action-icon" />
      <label> {fit.title} </label>
      <label> {fit.count} </label>
      {(onAdd && (
        <button className="btn-circle" onClick={onAdd}>
          <img src={icons.add} alt="+" />
        </button>
      )) || <div style={{ width: "27px" }}></div>}
    </div>
  );
}

export function PreparePage() {
  useTitle("闲来运动");
  const history = useHistory();
  const [_, setState] = useState({});
  const userDataState = useUserDataForWeixinShare();

  const poseNames = store.getSelectedActions();
  const selectedFits = fits.filter((f) => poseNames.indexOf(f.name) > -1);

  return (
    <div className="page-prepare">
      {userDataState.value?.isgz == "0" && (
        <header>
          <a className="button" href={mpURL}>
            关注公众号
          </a>
        </header>
      )}
      <main>
        {fits.map((fit) => (
          <ActionItem
            key={fit.name}
            fit={fit}
            onAdd={
              poseNames.indexOf(fit.name) < 0
                ? () => {
                    if (poseNames.indexOf(fit.name) < 0) {
                      store.setSelectedActions([...poseNames, fit.name]);
                      setState({});
                    }
                  }
                : null
            }
          />
        ))}
      </main>

      {selectedFits.length == 0 && (
        <footer>
          <div style={{ flex: 1 }}></div>
          <a
            className="btn-help"
            href="https://mp.weixin.qq.com/s?__biz=MzU2ODU5OTIyMw==&mid=2247483659&idx=1&sn=df51985e9ca5881b96db99fd423960f9&chksm=fc8a36d3cbfdbfc503e02f0a53e2765f40b5c6dc9970d8a2f2d9887f2961a8614e7939cf406d&token=2076576487&lang=zh_CN#rd"
          >
            <img src={icons.help} alt="?" />
            帮助
          </a>
        </footer>
      )}
      {selectedFits.length > 0 && (
        <footer>
          <div className="selected-pose-list">
            {selectedFits.map((fit) => (
              <div
                onClick={() => {
                  const i = poseNames.indexOf(fit.name);
                  if (i > -1) {
                    poseNames.splice(i, 1);
                    store.setSelectedActions(poseNames);
                    setState({});
                  }
                }}
              >
                <i>-</i>
                <Lottie
                  animationData={fit.animation}
                  className="action-icon small"
                />
              </div>
            ))}
          </div>
          <button
            className="btn-circle big"
            onClick={() => {
              if (!navigator.mediaDevices?.getUserMedia) {
                alert("苹果设备不支持访问摄像头");
                return;
              }
              history.push("/game");
            }}
          >
            Go
          </button>
        </footer>
      )}
    </div>
  );
}
