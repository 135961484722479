import "./weui.scss";

export function padZero(n: number | string) {
  if (!n && n !== 0) {
    return "";
  }
  const s = n.toString();
  return n < 10 ? "0" + s : s;
}

const hours = [...new Array(24).keys()].map((i) => ({
  label: padZero(i),
  value: i,
}));
const minutes = [...new Array(60).keys()].map((i) => ({
  label: padZero(i),
  value: i,
}));
function checkedDay(days: string, d: string) {
  if (!days || !d) {
    return "";
  }
  return (days.indexOf(d) > -1 && "checked") || "";
}

export function weuiTimePicker(
  days: string,
  hour: number | string,
  minute: number | string,
  onConfirm: (days: string, hour: number, minute: number) => void
) {
  console.log([hours, minutes]);

  const picker: HTMLDivElement = weui.picker(hours, minutes, {
    title: "选择时间",
    defaultValue: [padZero(hour), padZero(minute)],
    onChange: function (result: any) {
      console.log(result);
    },
    onConfirm: function (result: any) {
      console.log(result);
      const bb = picker.querySelectorAll<HTMLInputElement>(
        ".weui-picker-weekdays input"
      );
      const res = [] as string[];
      bb.forEach((e) => {
        if (e.checked) {
          res.push(e.value);
        }
      });
      const days = res.join("");
      onConfirm(days, result[0].value, result[1].value);
    },
  });

  //
  console.log(picker);

  const bd = picker.querySelector(".weui-half-screen-dialog__bd");
  bd?.insertAdjacentHTML(
    "afterbegin",
    `<div class="weui-picker-weekdays">
      <label><input type="checkbox" value="1" ${checkedDay(
        days,
        "1"
      )}/><b>周一</b></label>
      <label><input type="checkbox" value="2" ${checkedDay(
        days,
        "2"
      )}/><b>周二</b></label>
      <label><input type="checkbox" value="3" ${checkedDay(
        days,
        "3"
      )}/><b>周三</b></label>
      <label><input type="checkbox" value="4" ${checkedDay(
        days,
        "4"
      )}/><b>周四</b></label>
      <label><input type="checkbox" value="5" ${checkedDay(
        days,
        "5"
      )}/><b>周五</b></label>
      <label><input type="checkbox" value="6" ${checkedDay(
        days,
        "6"
      )}/><b>周六</b></label>
      <label><input type="checkbox" value="7" ${checkedDay(
        days,
        "7"
      )}/><b>周日</b></label>
    <div>`
  );
}
