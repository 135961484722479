type Drawable =
  | HTMLImageElement
  | HTMLCanvasElement
  | HTMLVideoElement
  | ImageBitmap;

export function copyVideoToCanvas(image: Drawable, canvas: HTMLCanvasElement) {
  // image image, bitmap, or canvas
  let videoWidth = image.width;
  let videoHeight = image.height;
  // if video element
  if (image instanceof HTMLVideoElement) {
    videoWidth = image.videoWidth;
    videoHeight = image.videoHeight;
  }
  canvas.width = videoWidth;
  canvas.height = videoHeight;
  const ctx: CanvasRenderingContext2D = canvas.getContext("2d")!;
  ctx.drawImage(image, 0, 0);
}

export function coverVideoToCanvas(
  image: Drawable,
  canvas: HTMLCanvasElement,
  flipped: boolean
) {
  // image image, bitmap, or canvas
  let videoWidth = image.width;
  let videoHeight = image.height;
  // if video element
  if (image instanceof HTMLVideoElement) {
    videoWidth = image.videoWidth;
    videoHeight = image.videoHeight;
  }

  const canvasWidth = canvas.clientWidth;
  const canvasHeight = canvas.clientHeight;

  if (canvasWidth / canvasHeight > videoWidth / videoHeight) {
    canvas.width = videoWidth;
    canvas.height = Math.ceil((canvasHeight / canvasWidth) * videoWidth);
  } else {
    canvas.width = Math.ceil((canvasWidth / canvasHeight) * videoHeight);
    canvas.height = videoHeight;
  }

  const dx = videoWidth - canvas.width;
  const dy = videoHeight - canvas.height;

  const ctx: CanvasRenderingContext2D = canvas.getContext("2d")!;
  ctx.resetTransform();
  ctx.translate(~~(dx / 2), ~~(dy / 2));
  if (flipped) {
    ctx.translate(canvas.width, 0);
    ctx.scale(-1, 1);
  }
  // canvas is already sized and cropped to center correctly
  ctx.drawImage(image, 0, 0);

  return ctx;
}
