import { store } from "./store";

const API_ENDPOINT = "https://sport.hgboss.com";

export async function getUserToken(code: string) {
  const res = await fetch(API_ENDPOINT + "/get_auth_token.aspx?code=" + code);
  if (res.status === 200) {
    return await res.text();
  }
  throw new Error("rpc.getUserToken error: " + (await res.text()));
}

export async function refreshUserToken(token: string) {
  const res = await fetch(
    API_ENDPOINT + "/get_refresh_token.aspx?token=" + token
  );
  if (res.status === 200) {
    return await res.text();
  }
  throw new Error("rpc.getUserToken error: " + (await res.text()));
}

export async function getUserData() {
  const token = store.getUserToken();
  const res = await fetch(API_ENDPOINT + "/get_user_data.aspx?token=" + token);
  if (res.status === 200) {
    return (await res.json()) as IUserData;
  }
  throw new Error("rpc.getUserData error: " + (await res.text()));
}

export async function getRankingList() {
  const token = store.getUserToken();
  const res = await fetch(
    API_ENDPOINT + "/get_ranking_list.aspx?token=" + token
  );
  if (res.status === 200) {
    return (await res.json()) as IUserData;
  }
  throw new Error("rpc.getRankingList error: " + (await res.text()));
}

export async function postUserExercise(name: string, score: number) {
  const token = store.getUserToken();

  // const formData = new FormData();
  // formData.set("token", token!.toString());
  // formData.set("name", name);
  // formData.set("score", score?.toString());
  // const res = await fetch(API_ENDPOINT + "/post_exercise.aspx", {
  //   method: "POST",
  //   cache: "no-cache",
  //   headers: {
  //     "Content-Type": "application/x-www-form-urlencoded",
  //   },
  //   body: formData,
  // });

  const params = new URLSearchParams();
  params.set("token", token!.toString());
  params.set("name", name);
  params.set("score", score?.toString());
  const res = await fetch(
    API_ENDPOINT + "/post_exercise.aspx?" + params.toString()
  );

  if (res.status === 200) {
    return await res.text();
  }
  throw new Error("rpc.postUserExercise error: " + (await res.text()));
}

export async function getWeixinConfig(url: string) {
  const token = store.getUserToken();
  const res = await fetch(
    API_ENDPOINT +
      "/get_ticket_singnature.aspx?token=" +
      token +
      "&url=" +
      encodeURIComponent(url)
  );
  if (res.status === 200) {
    return await res.json();
  }
  throw new Error("rpc.getWeixinConfig error: " + (await res.text()));
}

export async function setRemind(week: string, hour: number, minute: number) {
  const token = store.getUserToken();
  const params = new URLSearchParams();
  params.set("token", token!.toString());
  params.set("week", week);
  params.set("hour", hour?.toString());
  params.set("minute", minute?.toString());
  const res = await fetch(
    API_ENDPOINT + "/post_remind_set.aspx?" + params.toString()
  );
  if (res.status === 200) {
    return await res.json();
  }
  throw new Error("rpc.setRemind error: " + (await res.text()));
}

export async function unsetRemind() {
  const token = store.getUserToken();
  const res = await fetch(
    API_ENDPOINT + "/post_remind_close.aspx?token=" + token
  );
  if (res.status === 200) {
    return await res.json();
  }
  throw new Error("rpc.unsetRemind error: " + (await res.text()));
}

export async function postRecommend(uid: string) {
  const token = store.getUserToken();
  const res = await fetch(
    API_ENDPOINT + "/post_recommend.aspx?token=" + token + "&uid=" + uid
  );
  if (res.status === 200) {
    return await res.json();
  }
  throw new Error("rpc.postRecommend error: " + (await res.text()));
}

export async function getFenData() {
  const token = store.getUserToken();
  const res = await fetch(API_ENDPOINT + "/get_cash_data.aspx?token=" + token);
  if (res.status === 200) {
    return await res.json();
  }
  throw new Error("rpc.getFenData error: " + (await res.text()));
}

export async function postFenExchange(fen: number) {
  const token = store.getUserToken();
  const res = await fetch(
    API_ENDPOINT + "/post_exchange_cash.aspx?num=" + fen + "&token=" + token
  );
  if (res.status === 200) {
    return await res.json();
  }
  throw new Error("rpc.postFenExchange error: " + (await res.text()));
}

export async function getExerciseRecords(page = 1) {
  const token = store.getUserToken();
  const res = await fetch(
    API_ENDPOINT +
      "/get_sport_data.aspx?pagesize=30&currentpage=" +
      page +
      "&token=" +
      token
  );
  if (res.status === 200) {
    return await res.json();
  }
  throw new Error("rpc.getSportRecords error: " + (await res.text()));
}

export async function getFenRecords(page = 1) {
  const token = store.getUserToken();
  const res = await fetch(
    API_ENDPOINT +
      "/get_points_data.aspx?pagesize=100&currentpage=" +
      page +
      "&token=" +
      token
  );
  if (res.status === 200) {
    return await res.json();
  }
  throw new Error("rpc.getFenRecords error: " + (await res.text()));
}

// 获取积分兑换记录
export async function getFenUseRecords(page = 1) {
  const token = store.getUserToken();
  const res = await fetch(
    API_ENDPOINT +
      "/get_points_use_data.aspx?pagesize=100&currentpage=" +
      page +
      "&token=" +
      token
  );
  if (res.status === 200) {
    return await res.json();
  }
  throw new Error("rpc.getFenUseRecords error: " + (await res.text()));
}

export interface IUserData {
  userid: string;
  isgz: "0" | "1";
  nickname: string;
  sex: string;
  headimgurl: string;
  province: string;
  city: string;
  country: string;
  sportcount: string;
  points: string;

  rankinglist: {
    type: string;
    listtime: string;
    list: IRankingUser[];
  }[];

  remindset: {
    isopen: string;
    week: string;
    hour: string;
    minute: string;
  }[];

  recommend: {
    no: string;
    headimgurl: string;
    recommendcount: string;
  }[];
}

export interface IRankingUser {
  sort: string;
  nickname: string;
  headimgurl: string;
  score: string;
}

export interface ISportData {
  listtime: string;
  score: string;
  time: string;
  total: string;
  sportlist: { key: string; name: string; count: string }[];
}
